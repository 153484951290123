import Repository from "./Repository";
const resource = "/Auction_Template";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getAuctionTemplate() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
  getAuctionTemplateByid(id) {
    return Repository.get(resource + "/" + id);
  },
};
