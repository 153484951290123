import Repository from "./Repository";

const resource = "/Member_group";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },
  getMember() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },

  getMemberGroupById(id) {
    return Repository.get(resource + "/" + id);
  },
};
