import Repository from "./Repository";
const resource = "/EmailTemplate";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getEmail() {
    return Repository.get(resource);
  },
};
