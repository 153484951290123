import Repository from "./Repository";

const resource = "/SeatManagement";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },
  getSeat(id = "") {
    return Repository.get(resource + "/" + id);
  },
  searchSeat(payload) {
    return Repository.post(resource + "/search", payload);
  },
};
