import Repository from "./Repository";
const resource = "/Manageroom";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getManageroom() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
  getManageroomByid(id) {
    return Repository.get(resource + "/" + id);
  },
};
