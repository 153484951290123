import Repository from "./Repository";
const resource = "/Payment";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getPayment(id) {
    return Repository.get(resource + "?parent_id=" + id);
  },

  delete(data) {
    return Repository.post(resource + "/delete", data);
  },
  getPaymentByid(id) {
    return Repository.get(resource + "/" + id);
  },
  getPaymentBymemid(id) {
    return Repository.get(resource + "/mem_id/" + id);
  },
};
