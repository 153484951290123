<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h3>Income Report</h3>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="List Membership">
                      <el-select
                        style="width: 100%"
                        v-model="model.membership"
                        placeholder="List Membership"
                      >
                        <el-option
                          v-for="option in membershipData"
                          :value="option.id"
                          :label="option.membership_name"
                          :key="option.membership_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="List Subscriptions">
                      <el-select
                        style="width: 100%"
                        v-model="model.subscription"
                        placeholder="List Subscriptions"
                      >
                        <el-option
                          v-for="option in subscriptionData"
                          :value="option.id"
                          :label="option.subscription_name"
                          :key="option.subscription_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="List Auctions">
                      <el-select
                        style="width: 100%"
                        v-model="model.auction"
                        placeholder="List Auctions"
                      >
                        <el-option
                          v-for="option in itemData"
                          :value="option.id"
                          :label="option.item_name"
                          :key="option.item_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="List Charges">
                      <el-select
                        style="width: 100%"
                        v-model="model.charges"
                        placeholder="List Charges"
                      >
                        <el-option
                          v-for="option in chargeData"
                          :value="option.id"
                          :label="option.charge_name"
                          :key="option.charge_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Start Date">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ altFormat: 'd/m/Y', altInput: true }"
                        class="form-control datepicker"
                        v-model="model.startdate"
                      >
                      </flat-picker>
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="End Date">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ altFormat: 'd/m/Y', altInput: true }"
                        class="form-control datepicker"
                        v-model="model.enddate"
                      >
                      </flat-picker>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="onSearch()"
                  >
                    Search
                  </button>

                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="reset()"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="exportreport()"
                  >
                    Export
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="recieptData"
              >
                <template v-slot:columns>
                  <th>#</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Amount($)</th>
                </template>

                <template v-slot:default="row">
                  <td class="budget">
                    {{ row.item.charge_type }}
                  </td>
                  <td class="budget">
                    {{ row.item.desciption }}
                  </td>
                  <td class="budget">
                    {{ row.item.last_paid_date }}
                  </td>
                  <td class="budget">${{ row.item.paid_amount }}</td>
                </template>
              </base-table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const memberShipRepository = RepositoryFactory.get("membership");
const chargesRepository = RepositoryFactory.get("chargesRepo");
const itemRepository = RepositoryFactory.get("item");
const subscriptionRepository = RepositoryFactory.get("subscription");
const addchargeRepository = RepositoryFactory.get("addcharge");

import { ElSelect, ElOption } from "element-plus";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "income_report",

  data() {
    return {
      isLoading: false,
      isUpdateLoading: false,
      membershipData: [],
      chargeData: [],
      itemData: [],
      subscriptionData: [],
      recieptData: [],
      model: {},
      total: 0,
    };
  },

  methods: {
    exportreport() {
      const data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);

      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/AccountAddCharge/member_income_export?" +
          data,
        "_blank"
      );
    },

    reset() {
      this.recieptData = [];
      this.total = 0;
      this.model = {};
    },
    // Pushes posts to the server when called.
    async onSearch() {
      let data3 = await addchargeRepository.getda("", this.model);
      this.recieptData = [];
      this.total = 0;
      if (data3.data) {
        for (let index = 0; index < data3.data.length; index++) {
          const element = data3.data[index];
          if (element.paid_amount != 0) {
            this.recieptData.push(element);
            this.total += parseFloat(element.paid_amount);
          }
        }
        this.recieptData.push({
          last_paid_date: "Total",
          paid_amount: this.total,
        });
      }
    },

    getChargeList() {
      chargesRepository.getCharges().then((data) => {
        this.tableData = Object.freeze(data);
        this.chargeData = this.tableData.data;
      });
    },
    getSubscriptionList() {
      subscriptionRepository.getSubscription().then((data) => {
        this.tableData = Object.freeze(data);
        this.subscriptionData = this.tableData.data;
      });
    },
    getAuctionList() {
      itemRepository.getAuction().then((data) => {
        this.tableData = Object.freeze(data);
        this.itemData = this.tableData.data;
      });
    },
    getMembershipList() {
      memberShipRepository.getMembership().then((data) => {
        this.tableData = Object.freeze(data);
        this.membershipData = this.tableData.data;
      });
    },
  },

  mounted() {
    this.getChargeList();
    this.getMembershipList();
    this.getAuctionList();
    this.getSubscriptionList();
  },
};
</script>
<style></style>
