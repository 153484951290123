import Repository from "./Repository";
const resource = "/Charge";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getCharges() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
  getChargesByid(id) {
    return Repository.get(resource + "/" + id);
  },
};
