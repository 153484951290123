import Repository from "./Repository";
const resource = "/AccountAddCharge";
export default {

  sendEmails(){
    return Repository.get(resource + "/sendemails/");
  },
  sendEmail(payload) {
    return Repository.get(resource + "/sendemail/" + payload);
  },
  create(payload) {
    return Repository.post(resource, payload);
  },

  multiCreate(payload) {
    return Repository.post(resource + "/update_multi", payload);
  },

  getAccountCharges() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },

  getda(id = "", data = "") {
    return Repository.get(resource + "/get_data/" + id, {
      params: {
        data,
      },
    });
  },

  getdataBymember(id = "") {
    return Repository.get(resource + "/bymember/" + id);
  },

  get_specific_data(id) {
    return Repository.get(resource + "/get_specific_data/" + id);
  },

  create_auction_charge(payload) {
    return Repository.post(resource + "/auction_charge", payload);
  },

  getReport306090(payload) {
    return Repository.post(resource + "/month_report/", payload);
  }
};
