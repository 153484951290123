import UserRepository from "./userRepository";
import memberRepository from "./memberRepository";
import membergroupRepository from "./membergroupRepository";
import membershipRepository from "./membershipRepository";
import chargeRepository from "./chargeRepository";
import subscriptionRepository from "./subscriptionRepository";
import itemRepository from "./itemRepository";
import manageRoomRepository from "./manageRoomRepository";
import auctionRepository from "./auctionRepository";
import bulkRepository from "./bulkRepository";
import paymentRepository from "./paymentRepository";
import recieptRepository from "./recieptRepository";
import addchargeRepository from "./addchargeRepository";
import seatManagementRepository from "./seatManagementRepository";
import emailRepository from "./emailRepository";
import auctiontemplateRepository from "./auctiontemplateRepository";
import calenderRepositoy from "./calenderRepositoy";
const repositories = {
  users: UserRepository,
  member: memberRepository,
  membergroup: membergroupRepository,
  membership: membershipRepository,
  chargesRepo: chargeRepository,
  subscription: subscriptionRepository,
  item: itemRepository,
  manageroom: manageRoomRepository,
  auction: auctionRepository,
  bulk: bulkRepository,
  payment: paymentRepository,
  reciept: recieptRepository,
  addcharge: addchargeRepository,
  seat: seatManagementRepository,
  email: emailRepository,
  auction_template: auctiontemplateRepository,
  calender: calenderRepositoy,
  // other repositories ...
};

export const RepositoryFactory = {
  get: (name) => repositories[name],

  JSON_to_URLEncoded(element, key, list) {
    let new_list = list || [];
    if (typeof element === "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  },
};
