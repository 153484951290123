import Repository from "./Repository";
const resource = "/Bulk_charge";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getBulk() {
    return Repository.get(resource);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
  getBulkByid(id) {
    return Repository.get(resource + "/" + id);
  },
};
