import Repository from "./Repository";
const resource = "/Reciept";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  getReciept(id = "") {
    return Repository.get(resource + "/" + id);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
  print_get(id = "") {
    return Repository.get(resource + "/" + "print" + "/" + id);
  },
  getNumber() {
    return Repository.get(resource + "/autoincrement");
  },
};
