import Repository from "./Repository";

const resource = "/User";
export default {
  login(payload) {
    return Repository.post(resource + "/login", payload);
  },
  create(payload) {
    return Repository.post(resource, payload);
  },

  getUser(id = "") {
    return Repository.get(resource + "/" + id);
  },

  delete(data) {
    return Repository.post(resource, data);
  },
};
