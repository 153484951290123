import Repository from "./Repository";
const resource = "/Calender";
export default {
  create(payload) {
    return Repository.post(resource, payload);
  },

  delete(data) {
    return Repository.post(resource + "/delete", data);
  },
  getCalenderid() {
    return Repository.get(resource);
  },
};
